<template>
  <form class="login-frame" action="submit">
    <h3 class="fw-bold mb-4">{{ t("registration") }}:</h3>
    <div class="d-flex mb-3 align-items-center">
      <p class="star">*</p>
      <input
        type="email"
        class="input"
        :placeholder="t('yourEmail')"
        v-model="email"
      />
    </div>
    <p class="mb-1">
      <small>{{ t("passwordTooShort") }}</small>
    </p>
    <div class="d-flex mb-3 align-items-center mt-0">
      <p class="star">*</p>
      <input
        :type="seePassword ? 'text' : 'password'"
        class="input"
        :placeholder="t('enterNewPassword')"
        v-model="password"
      />
      <b-button variant="white" class="eye" @click="seePassword = !seePassword">
        <img src="../assets/eye.png" alt="" />
      </b-button>
    </div>
    <div class="d-flex mb-3 align-items-center">
      <p class="star">*</p>
      <input
        :type="seePassword ? 'text' : 'password'"
        class="input"
        :placeholder="t('repeatNewPassword')"
        v-model="password_confirmation"
      />
    </div>
    <div class="d-flex mb-3 align-items-center">
      <p class="star">*</p>
      <input
        type="text"
        class="input"
        :placeholder="t('yourName')"
        v-model="name"
      />
    </div>
    <div class="d-flex align-items-center checkbox-row mt-2">
      <div class="round">
        <input type="checkbox" id="checkbox" v-model="acceptAgreement" />
        <label for="checkbox"></label>
      </div>
      <label class="remember" for="checkbox">
        <router-link to="/policy">{{ t("doAcceptPolicy") }}</router-link>
      </label>
    </div>
    <vue-recaptcha
      sitekey="6LcHiQwdAAAAACdTEEeEYA0kG77VmqwYH36O6hBw"
      :loadRecaptchaScript="true"
      class="captcha mt-2"
      :language="currLang"
      @verify="verify"
    />
    <button type="submit" @click="submit" class="main mt-3">
      {{ t("registrate") }}
    </button>
    <google-button />
    <!-- <p class="text-muted mt-4">{{ t('haveProfile') }}</p> -->
    <router-link class="mt-4" to="/login">{{ t("login") }}</router-link>
  </form>
</template>

<script>
import GoogleButton from "../components/GoogleButton.vue";
import vueRecaptcha from "vue-recaptcha";

export default {
  components: { GoogleButton, vueRecaptcha },
  name: "Registrate",
  metaInfo() {
    return {
      title: "Techvibe " + this.t("registrate"),
      meta: [
        { name: "description", content: "Techvibe " + this.t("registrate") },
        { property: "og:title", content: "Techvibe " + this.t("registrate") },
        { property: "og:site_name", content: "Techvibe" },
        { property: "og:type", content: "website" },
      ],
      htmlAttrs: {
        lang: this.currLang,
      },
    };
  },
  data() {
    return {
      seePassword: false,
      password_confirmation: "",
      name: "",
      email: "",
      password: "",
      acceptAgreement: true,
      notRobot: false,
    };
  },
  methods: {
    googleAuth() {
      event?.preventDefault();
    },
    verify() {
      this.notRobot = true;
    },
    async submit() {
      event?.preventDefault();
      if (!this.notRobot) {
        this.error(this.t("verifyNotRobot"));
        return;
      }
      if (this.password.length < 8) {
        this.warning(this.t("passwordTooShort"));
        return;
      } else if (this.password_confirmation != this.password) {
        this.error(this.t("wrongSecondPassword"));
        return;
      } else if (!this.name) {
        this.error(this.t("enterName"));
      } else if (!this.email) {
        this.error(this.t("enterEmail"));
      } else if (!this.acceptAgreement) {
        this.error(this.t("acceptPolicy"));
      } else {
        try {
          await this.$store.dispatch("registrate", {
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            name: this.name,
          });
          this.$router.push("/");
        } catch (e) {
          const data = e.response?.data?.message || [];
          if (data.includes("email_unique")) {
            this.warning({
              text: this.t("suchEmailExists"),
              path: "/login",
            });
          } else if (data.includes("email")) {
            this.error(this.t("wrongEmail"));
          } else {
            this.error(data[0]);
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 100px;
  width: 790px;
  height: 700px;
  box-shadow: 0px -40px 32px -24px rgba(0, 0, 0, 0.12),
    0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  backdrop-filter: blur(32px);
  background-size: cover;
  background-position: center;
  background-image: url("../assets/loginbg.png");
  border-radius: 15px;

  .input {
    width: 250px;
    height: 40px;
  }
  .star {
    color: #ea443581;
    font-size: 28px;
    width: 10px;
    height: 10px;
    margin-left: -20px;
    margin-right: 10px;
  }
  button.main,
  button.secondary {
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 250px;
  }

  button.secondary {
    img {
      margin-right: 10px;
    }
  }
  .captcha {
    transform: scale(0.83);
    transform-origin: 0% 0;
    margin-left: 52px;
    margin-top: 10px;
    margin-bottom: -10px;
  }
  .eye {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: -40px;
  }
  .checkbox-row {
    width: 250px;
    label {
      cursor: pointer;
    }
    .remember {
      font-size: 14px;
      text-align: left;
      margin-left: 20px;
    }
    .round {
      position: relative;
    }
    .round label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .round label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 4px;
      opacity: 0;
      position: absolute;
      transform: rotate(-45deg);
      width: 8px;
    }
    .round input[type="checkbox"] {
      visibility: hidden;
    }
    .round input[type="checkbox"]:checked + label {
      background-color: #66bb6a;
      border-color: #66bb6a;
    }
    .round input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
  }
}
@media (max-width: 800px) {
  .login-frame {
    border-radius: 0 !important;
    margin-top: 3px !important;
    width: 100% !important;
    height: 700px !important;
    box-shadow: none !important;
  }
}
</style>